import { NgModule } from '@angular/core';

import { HeaderComponent } from './components/header/header.component';
import { MaterialModule} from './material/material.module';
import { LoaderDirective } from './directives/loader.directive';
import { FeatureSearchComponent } from './components/feature-search/feature-search.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { ManualHotspotComponent } from './components/manual-hotspot/manual-hotspot.component';
import { RectangleComponent } from './components/manual-hotspot/rectangle-component/rectangle-component';
import { ManualHotspotDirective } from './components/manual-hotspot/manual-hotspot.directive';
import { ManualHotspotService } from './components/manual-hotspot/manual-hotspot.service';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { SyncDialogComponent } from './components/sync-dialog/sync-dialog.component';
@NgModule({
  imports: [
    MaterialModule,
    FormsModule,
    CommonModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    })
  ],
  declarations: [
    HeaderComponent,
    LoaderDirective,
    FeatureSearchComponent,
    ProgressSpinnerComponent,
    ManualHotspotComponent,
    ManualHotspotDirective,
    RectangleComponent,
    ConfirmDialogComponent,
    SyncDialogComponent
  ],
    exports: [
        LoaderDirective,
        HeaderComponent,
        MaterialModule,
        FeatureSearchComponent,
        ProgressSpinnerComponent,
        ManualHotspotComponent,
        ManualHotspotDirective,
        ConfirmDialogComponent,
        SyncDialogComponent
    ],
  providers: [
    ManualHotspotService,
  ],
  entryComponents: [
    RectangleComponent,
    ConfirmDialogComponent
  ]
})
export class SharedModule { }

