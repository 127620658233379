import { Injectable } from '@angular/core';
import { Feature, FeatureShort } from '../models/feature.model';
import { SidekickFeature } from '../models/sidekick-feature.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureCommService {
  isDataChanged: boolean;
  selectedFeature: Feature;
  selectedFeatureId: number;
  featureList: FeatureShort[];
  types =  [];
  industries = [];
  defaultIndustry;
  defaultIndustryTitle;
  defaultIndustryId;
  sidekickFeatureList: SidekickFeature[] = [];
  selectedFeatureSubject: Subject<Feature> = new Subject();
  selectedFeature$ = this.selectedFeatureSubject.asObservable();
  retainTab = false;
  categoryTypeTempArr = [];
  categoryTypeIDTempArr = [];
  categoryType: any;
  selectedCategoryType;
  version: string;
  totalHotspot = 0;
  industryTypeObj;
  showSyncPopup = false;
  public deletedManualHotspotIdArray: any = [];
  constructor() {
  }

  setSelectedFeature(feature: Feature) {
    this.selectedFeatureSubject.next(feature);
    this.selectedFeatureId = feature.id;
  }
  setFeatureList(featureList: FeatureShort[]) {
    this.featureList = featureList;
  }

  getFeatureList(): FeatureShort[] {
    return this.featureList;
  }

  setSidekickFeatures(sidekickFeatures: SidekickFeature[]) {
    this.sidekickFeatureList = sidekickFeatures;
  }

  getSidekickFeatures(): SidekickFeature[] {
    return this.sidekickFeatureList;
  }

  setDataChanged(flag: boolean) {
    if (this.isDataChanged !== flag) {
      this.isDataChanged = flag;
    }
  }
}
