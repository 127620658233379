import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class FeatureCommService {
    constructor() {
        this.types = [];
        this.industries = [];
        this.sidekickFeatureList = [];
        this.selectedFeatureSubject = new Subject();
        this.selectedFeature$ = this.selectedFeatureSubject.asObservable();
        this.retainTab = false;
        this.categoryTypeTempArr = [];
        this.categoryTypeIDTempArr = [];
        this.totalHotspot = 0;
        this.showSyncPopup = false;
        this.deletedManualHotspotIdArray = [];
    }
    setSelectedFeature(feature) {
        this.selectedFeatureSubject.next(feature);
        this.selectedFeatureId = feature.id;
    }
    setFeatureList(featureList) {
        this.featureList = featureList;
    }
    getFeatureList() {
        return this.featureList;
    }
    setSidekickFeatures(sidekickFeatures) {
        this.sidekickFeatureList = sidekickFeatures;
    }
    getSidekickFeatures() {
        return this.sidekickFeatureList;
    }
    setDataChanged(flag) {
        if (this.isDataChanged !== flag) {
            this.isDataChanged = flag;
        }
    }
}
FeatureCommService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureCommService_Factory() { return new FeatureCommService(); }, token: FeatureCommService, providedIn: "root" });
