import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
export class AppGuardService {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canActivate() {
        if (this.authService.isAuthenticated()) {
            return true;
        }
        else {
            if (!this.authService.isRegistered()) {
                this.router.navigate(['auth/sign-up']);
                return false;
            }
            else {
                this.router.navigate(['auth/sign-in']);
                return false;
            }
        }
    }
}
AppGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppGuardService_Factory() { return new AppGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: AppGuardService, providedIn: "root" });
