import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpClient, HttpRequest, HttpResponse, HttpHeaders} from '@angular/common/http';
import {HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, BehaviorSubject, throwError} from 'rxjs';
import {catchError, switchMap, take, filter, finalize, map} from 'rxjs/operators';
import {BaseService} from '@core/base.service';
import {AuthService } from '@core/auth.service';
import { ToasterService } from '@core/toaster.service';
import { Constants } from '@shared/services/constants.service';

@Injectable()
export class AuthInterceptor extends BaseService implements HttpInterceptor {


    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        public router: Router,
        public toaster: ToasterService,
        public http: HttpClient,
        private authService: AuthService) {
        super(router, toaster);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.getAuthToken();
        req = this.addAuthenticationToken(req);

        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err && (err.status === 401 || err.status === 422 || err.status === 404)) {
                    if (
                      (req.url.indexOf('sign_in.json') !== -1) ||
                      (req.url.indexOf('users.json') !== -1) ||
                      (req.url.indexOf('token_confirmation.json') !== -1)
                    ) {
                        return this.loginErrorHndlr(err);
                    } else {
                        if (this.refreshTokenInProgress) {
                            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                            // which means the new token is ready and we can retry the request again
                            return this.refreshTokenSubject.pipe(
                                filter(result => result !== null),
                                take(1),
                                switchMap(() => next.handle(this.addAuthenticationToken(req)))
                            );
                        } else {
                            this.refreshTokenInProgress = true;
                            // Set the refreshTokenSubject to null so that subsequent API calls will wait until
                            // the new token has been retrieved
                            this.refreshTokenSubject.next(null);
                            const params = {
                                uuid:  this.getUuid(),
                                refresh_token: this.getRefreshToken()
                            };
                            return this.http.put(this.baseUrl + Constants.apiEndPoints.refreshToken, params).pipe(
                                switchMap( (data: any) => {
                                        this.setAuthToken(data.user.access_token);
                                        this.setRefreshToken(data.user.refresh_token);
                                        this.setUuid(data.user.user_id);
                                        this.refreshTokenSubject.next(true);
                                        return next.handle(this.addAuthenticationToken(req));
                                }),
                                finalize(() => this.refreshTokenInProgress = false)
                            );
                        }
                    }
                } else {
                    return this.genericError(err);
                }
            }),
            map( (response: any) => {
                if ( req.method.toLocaleLowerCase() === 'put') {
                    if ( req.url.indexOf('/features') !== -1) {
                        if (response.body) {
                            this.genericSuccess(response);
                        }
                    }
                }
                return response;
            })
        );
    }

    private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
        if (!this.getAuthToken()) {
            return request;
        }
        return request.clone({
            headers: request.headers.set('authtoken', this.getAuthToken())
        });
    }

}
