import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AppGuardService implements CanActivate {
    constructor(private authService: AuthService, public router: Router) {
    }

    canActivate(): boolean {
        if (this.authService.isAuthenticated()) {
            return true;
        } else {
            if (!this.authService.isRegistered()) {
                this.router.navigate(['auth/sign-up']);
                return false;
            } else {
                this.router.navigate(['auth/sign-in']);
                return false;
            }
        }
    }
}

