import { Injectable } from '@angular/core';
import { RectangleComponent } from './rectangle-component/rectangle-component';

@Injectable({
    providedIn: 'root'
})
export class ManualHotspotService {

  private shapesComponents: RectangleComponent[] = [];

  private selectedComponent: RectangleComponent;

  constructor() {
  }

  getRectangleComponents(): RectangleComponent[] {
    return this.shapesComponents;
  }

  removeSelectedRectangleComponent(component: RectangleComponent): void {
    const comps = this.getRectangleComponents();
    const index = comps.indexOf(component, 0);
    if (index > -1) {
      comps.splice(index, 1);
    }
  }

  removeAllRectangleComponents(): void {
    this.shapesComponents = [];
  }

  setRectangleComponent(component: RectangleComponent): void {
    this.selectedComponent = component;
    this.shapesComponents.push(component);
  }

  getRectangleComponent(): RectangleComponent {
    return this.selectedComponent;
  }

  findRectangleComponent(name: string): RectangleComponent {
    return this.shapesComponents.find(x => x.shape.shapeProperties.name === name);
  }
}
