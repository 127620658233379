import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  @Input() confirmHeaderTxt: string;
  @Input() msg: string;
  @Input() cancelBtnTxt: string;
  @Input() confirmBtnTxt: string;
  @Input() confirmClass: string;

  constructor(private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) data) {
    this.confirmHeaderTxt = (data && data.confirmHeaderTxt) ?  data.confirmHeaderTxt : 'Are you sure?';
    this.msg = (data && data.msg) ?  data.msg : 'Are you sure you want to continue?';
    this.cancelBtnTxt = (data && data.cancelBtnTxt) ?  data.cancelBtnTxt : 'Cancel';
    this.confirmBtnTxt = (data && data.confirmBtnTxt) ?  data.confirmBtnTxt : 'Ok';
    this.confirmClass = (data && data.confirmClass) ?  data.confirmClass : 'success';
  }
}

