<ng-template #shapeTemplate>
    <svg:rect attr.id="{{shape.shapeProperties.name}}" fill="url(#img1)" class="draggable" attr.x="{{ shape.originX }}" attr.y="{{ shape.originY }}"  [ngStyle]="setStyles()">
        <title>{{ shape.shapeProperties.name }}
        </title>
    </svg:rect>
    <svg xmlns="http://www.w3.org/2000/svg" 
        xmlns:xlink="http://www.w3.org/1999/xlink"  
        attr.x="{{ shape.originX}}"
        attr.y="{{ shape.originY}}" 
        attr.width="{{ shape.width}}" 
        attr.height="{{ shape.height}}"
        (click)="rectangleShapeSelect(shape.shapeProperties.name)"
        class="draggable drag-cursor"
        [class.drag-cursor]="isSelected">
 <image class="draggable" attr.id="{{shape.shapeProperties.name}}"
 attr.xlink:href="{{shape.imgUrl}}" 
   [ngStyle]="{width: shape.width, height: shape.height}"/>
</svg>
    <!-- <svg:g *ngIf="isSelected"> -->
        
        <!-- <svg:rect attr.x="{{ shape.originX - 15 }}"  class="resize" attr.y="{{ shape.originY - 15 }}" width="20" height="20" style="cursor: se-resize;stroke: green; stroke-width: 1; fill:green" /> -->
        <!-- <svg:rect attr.x="{{ shape.originX + shape.width - 5 }}"  class="resize" attr.y="{{ shape.originY - 15 }}" width="20" height="20"
            style="stroke: green; stroke-width: 1; fill:green; cursor: se-resize" /> -->
        <!-- <circle attr.id="{{shape.shapeProperties.name}}"  attr.cx="{{ shape.originX + shape.width }}" class="delete" attr.cy="{{ shape.originY }}" r="12" stroke="black" stroke-width="0" fill="red" /> -->
        <!-- <svg:rect attr.x="{{ shape.originX - 2 }}"  class="resize" attr.y="{{ shape.originY + shape.height - 2 }}" width="20" height="20"
            style="stroke: green; stroke-width: 1; fill:green; cursor: se-resize" /> -->
        <!-- <svg:rect attr.x="{{ shape.originX + shape.width - 2 }}"  class="resize" attr.y="{{ shape.originY + shape.height - 2 }}" width="20"
            height="20" style="stroke: green; stroke-width: 1; fill:green; cursor: se-resize" /> -->
    <!-- </svg:g> -->
    <svg xmlns="http://www.w3.org/2000/svg" *ngIf="isSelected"
        xmlns:xlink="http://www.w3.org/1999/xlink"  
        attr.x="{{ shape.originX + shape.width - 4 }}"
          class="resize" 
          attr.y="{{ shape.originY + shape.height - 4 }}"
          width="20"
          height="20"
        class="draggable">
 <image class="resize" attr.id="{{shape.shapeProperties.name}}"
 xlink:href="../../../assets/images/resize.png" 
   height="20" width="20"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" *ngIf="isSelected"
        xmlns:xlink="http://www.w3.org/1999/xlink"  
        attr.x="{{ shape.originX + shape.width - 18 }}"
        attr.y="{{ shape.originY - 2 }}"
          class="delete" 
          width="20"
          height="20"
          style="cursor:pointer;">
 <image class="delete" attr.id="{{shape.shapeProperties.name}}"
 xlink:href="../../../assets/images/delete.png" 
   height="20" width="20"/>
</svg>

<!-- <svg:rect attr.x="{{ shape.originX + shape.width - 5}}"  attr.y="{{ shape.originY - 15 }}" width="20" height="20"
            style="stroke: green; stroke-width: 1; fill:green; cursor: se-resize" /> -->
</ng-template>
